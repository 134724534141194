/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider, nsUtilProvider) {
  $stateProvider
    .state('locations', {
      parent: 'defaultlayout',
      url: '/locations?disableGeolocation&onlyDistributer',
      templateUrl: 'apps/locations/templates/acp-section-locations.ng.html',
      params: {
        disableGeolocation: null,
        onlyDistributer: null
      },
      data: {
        permissions: {
          only: 'isLocationFinderEnabled',
          redirectTo: {
            isLocationFinderEnabled: 'dashboard'
          }
        }
      },
      resolve: {
        acpGoogleMaps: /* @ngInject */ function (module, acpGoogleMaps) {
          return acpGoogleMaps;
        },
        module: /* @ngInject */ function ($ocLazyLoad) {
          return import(
            /* webpackChunkName: "acp.apps.locations" */ 'apps/locations'
          ).then($ocLazyLoad.loadModule);
        }
      }
    })
    .state('atm-finder', {
      parent: 'root',
      url: '/atms',
      templateUrl: 'apps/locations/templates/acp-atm-finder.ng.html',
      data: {
        permissions: {
          only: 'atmFinderEnabled',
          redirectTo: {
            atmFinderEnabled: 'dashboard'
          }
        }
      },
      resolve: {
        acpGoogleMaps: /* @ngInject */ function (module, acpGoogleMaps) {
          return acpGoogleMaps;
        },
        module: /* @ngInject */ function ($ocLazyLoad) {
          return import(
            /* webpackChunkName: "acp.apps.locations" */ 'apps/locations'
          ).then($ocLazyLoad.loadModule);
        }
      }
    })
    .state('public.atm-finder', {
      url: '/atm-finder',
      templateUrl: 'apps/locations/templates/acp-atm-finder-unauthed.ng.html',
      data: {
        permissions: {
          only: 'password_unauthed',
          redirectTo: {
            password_unauthed: 'atm-finder'
          }
        }
      },
      resolve: {
        acpGoogleMaps: /* @ngInject */ function (module, acpGoogleMaps) {
          return acpGoogleMaps;
        },
        module: /* @ngInject */ function ($ocLazyLoad) {
          return import(
            /* webpackChunkName: "acp.apps.locations" */ 'apps/locations'
          ).then($ocLazyLoad.loadModule);
        }
      }
    })
    .state('public.locations', {
      url: '/location-finder?disableGeolocation',
      templateUrl:
        'apps/locations/templates/acp-section-locations-unauthed.ng.html',
      data: {
        permissions: {
          only: 'password_unauthed',
          redirectTo: {
            password_unauthed: 'dashboard'
          }
        }
      },
      resolve: {
        acpGoogleMaps: /* @ngInject */ function (module, acpGoogleMaps) {
          return acpGoogleMaps;
        },
        pageTitle: /* @ngInject */ function (module, $injector) {
          return $injector.invoke(
            nsUtilProvider.getPageTitle('apps/locations')
          );
        },
        pageH1: /* @ngInject */ function (module, $injector) {
          return $injector.invoke(
            nsUtilProvider.getPageTitle('apps/locations', 'page-h1')
          );
        },
        pageMetaDescription: /* @ngInject */ function (module, $injector) {
          return $injector.invoke(
            nsUtilProvider.getPageTitle('apps/locations', 'meta-description')
          );
        },
        module: /* @ngInject */ function ($ocLazyLoad) {
          return import(
            /* webpackChunkName: "acp.apps.locations" */ 'apps/locations'
          ).then($ocLazyLoad.loadModule);
        }
      }
    })
    .state('offers', {
      parent: 'root',
      url: '',
      redirectTo: '.new',
      template: '<ui-view/>'
    })

    .state('offers.new', {
      parent: 'root',
      url: '/offers',
      template: '<acp-offers-processing></acp-offers-processing>',
      data: {
        permissions: {
          only: 'paybackrewards_eligible',
          redirectTo: {
            paybackrewards_eligible: 'dashboard'
          }
        }
      },
      resolve: {
        // acpGoogleMaps needs to be injected manually
        acpGoogleMaps: /* @ngInject */ function (module, $injector, $q) {
          var media = $injector.get('acpMedia');
          return media('gt-sm') ? $injector.get('acpGoogleMaps') : $q.resolve();
        },
        module: /* @ngInject */ function ($ocLazyLoad) {
          return import(
            /* webpackChunkName: "acp.apps.locations" */ 'apps/locations'
          ).then($ocLazyLoad.loadModule);
        }
      },
      onEnter: /* @ngInject */ function (
        PermTransitionProperties,
        acpOffersDomainModel
      ) {
        if (PermTransitionProperties.options.fromSidenav) {
          acpOffersDomainModel.viewAllOffersLinkClickEvent();
        }
      }
    });
}

export default states;
